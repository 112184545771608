import { FC, useMemo, useState } from "react";
import classNames from "classnames";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import { PrinterIcon } from "@heroicons/react/24/outline";
import { PieceInterface } from "../interfaces";
import DropdownMenu from "./DropdownMenu";

export enum PieceMenuOptions {
  EDIT,
  DUPLICATE,
  DELETE,
}
interface PieceTableOption {
  title: string;
  value: PieceMenuOptions;
}

const OPTIONS: PieceTableOption[] = [
  { title: "Editar", value: PieceMenuOptions.EDIT },
  { title: "Duplicar", value: PieceMenuOptions.DUPLICATE },
  { title: "Eliminar", value: PieceMenuOptions.DELETE },
];
interface PieceTableProps {
  pieces: PieceInterface[];
  editable?: boolean;
  editMode?: boolean;
  editIndex?: number;
  printable?: boolean;
  maxSumWeight?: number;
  onClickPieceMenu?: (option: PieceTableOption, index: number) => void;
  onPrint?: (pieceNumber: number) => void;
}

const PieceTable: FC<PieceTableProps> = ({
  pieces,
  editable,
  editMode,
  editIndex,
  printable,
  maxSumWeight,
  onClickPieceMenu = () => {},
  onPrint = () => {},
}) => {
  const [openPieceMenu, setOpenPieceMenu] = useState(-1);

  const orderedPieces = useMemo(() => {
    return [...pieces].sort(
      (a, b) => (a.pieceNumber ?? Infinity) - (b.pieceNumber ?? Infinity)
    );
  }, [pieces]);

  const weightSum = useMemo(() => {
    return orderedPieces.reduce((sum, piece) => sum + piece.weight, 0);
  }, [orderedPieces]);

  return (
    <table className="table-auto w-full">
      <thead>
        <tr>
          <th className="text-left px-4 py-2 font-semibold text-xs" />
          <th className="text-left px-4 py-2 font-semibold text-xs">PESO</th>
          <th className="text-left px-4 py-2 font-semibold text-xs">
            CATEGORÍA
          </th>
          <th className="text-left px-4 py-2 font-semibold text-xs">
            DIMENSIONES
          </th>
          <th
            className={classNames(
              "w-8 text-left px-4 py-2 font-semibold text-xs",
              (!editable || editMode) && !printable && "hidden"
            )}
          />
        </tr>
      </thead>
      <tbody>
        {orderedPieces.map((piece, index) => (
          <tr
            key={index}
            className={classNames(
              index % 2 === 0 && "bg-gray-50",
              editMode && editIndex === index && "border-2 border-indigo-600"
            )}
          >
            <td className="px-4 py-4 text-xs text-gray-500 font-semibold">
              {index + 1}
            </td>
            <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
              {piece.weight.toFixed(2)} Kg
            </td>
            <td className="px-4 py-4 text-xs text-gray-500 font-semibold">
              {piece.category.name}
            </td>
            <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
              {piece.height.toFixed(0)}cm - {piece.width.toFixed(0)}cm -{" "}
              {piece.length.toFixed(0)}cm
            </td>
            <td
              className={classNames(
                "py-2 min-w-12 flex flex-1 h-full gap-1 justify-end items-center pr-3 relative",
                (!editable || editMode) && !printable && "hidden"
              )}
            >
              <div
                title="Menú de opciones"
                data-te-toggle="tooltip"
                onClick={() => {
                  setOpenPieceMenu(index);
                }}
                className={classNames(
                  "relative rounded-full hover:cursor-pointer p-2",
                  (!editable || editMode) && "hidden"
                )}
              >
                <EllipsisVerticalIcon
                  className="h-5 w-5 text-gray3-600"
                  aria-hidden="true"
                />
              </div>

              <DropdownMenu
                open={openPieceMenu === index}
                setOpen={(open) => setOpenPieceMenu(open ? index : -1)}
                onSelect={(option) => onClickPieceMenu(option, index)}
                options={OPTIONS.filter(
                  (option) =>
                    option.title !== "Duplicar" ||
                    maxSumWeight === undefined ||
                    weightSum + piece.weight <= maxSumWeight
                )}
                className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50"
              />

              <div
                title="Imprimir"
                data-te-toggle="tooltip"
                onClick={() => onPrint(piece.pieceNumber!)}
                className={classNames(
                  "rounded-full p-2 bg-blue-50 hover:bg-blue-100 cursor-pointer",
                  !printable && "hidden"
                )}
              >
                <PrinterIcon
                  className="h-5 w-5 text-indigo-600"
                  aria-hidden="true"
                />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PieceTable;
