import React, { FC, useEffect, useMemo, useState } from "react";
import {
  PIECES_INITIAL_VALUES,
  PriceCalculatorFormValues,
} from "./PriceCalculatorForm";
import { PrimaryButton } from "../../components/Buttons";
import {
  DeliveryType,
  ItemInterface,
  PaymentMode,
  ShipmentService,
} from "../../interfaces";
import classNames from "classnames";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  getDeliveryDistance,
  getService,
  getShipmentItems,
  getShipmentRates,
  getTier,
} from "../../services";
import { ServiceDTO } from "../../interfaces/Dtos/ServiceDTO";
import PieceTable, { PieceMenuOptions } from "../../components/PieceTable";
import ShipmentItemTable from "../../components/ShipmentItemTable";
import { useAppSelector } from "../../store/hooks";

interface PriceCalculatorResumeProps {
  items: ItemInterface[];
  values: PriceCalculatorFormValues;
  editMode: boolean;
  editIndex: number;
  setItems: (items: ItemInterface[]) => void;
  setValues: (values: PriceCalculatorFormValues) => void;
  setEditMode: (mode: boolean) => void;
  setEditIndex: (index: number) => void;
  setInitialValues: (values: typeof PIECES_INITIAL_VALUES) => void;
}

const PriceCalculatorResume: FC<PriceCalculatorResumeProps> = ({
  items,
  values,
  editMode,
  editIndex,
  setItems,
  setValues,
  setEditMode,
  setEditIndex,
  setInitialValues,
}) => {
  const applicationID = useAppSelector(
    (state) => state.inmutable.appData.applicationID
  );

  const [service, setService] = useState<ServiceDTO>();
  const [deliveryDistance, setDeliveryDistance] = useState(0);
  const [baseShipmentItems, setBaseShipmentItems] = useState<ItemInterface[]>(
    []
  );

  const isQuotable = useMemo(() => {
    return (
      values.buSource &&
      values.buConsignee &&
      values.consigneeAddress &&
      values.pieces.length > 0 &&
      values.totalChargedWeight > 0 &&
      (values.declaredValue > 0 ||
        (values.service === ShipmentService.DOCUMENT &&
          values.paymentMode === PaymentMode.CONTADO))
    );
  }, [
    values.buSource,
    values.buConsignee,
    values.consigneeAddress,
    values.pieces,
    values.totalChargedWeight,
    values.declaredValue,
    values.service,
    values.paymentMode,
  ]);

  const deletePiece = (index: number) => {
    const newPieces = [...values.pieces];
    newPieces.splice(index, 1);
    setValues({ ...values, pieces: newPieces });
  };

  const duplicatePiece = (index: number) => {
    const newPieces = [...values.pieces];
    newPieces.push({ ...newPieces[index] });
    setValues({ ...values, pieces: newPieces });
  };

  const handleMenuClick = (
    option: { value: PieceMenuOptions },
    index: number
  ) => {
    if (option.value === PieceMenuOptions.EDIT) {
      setEditMode(true);
      setEditIndex(index);
      setInitialValues({
        category: values.pieces[index].category.name,
        weight: values.pieces[index].weight.toFixed(2),
        value: values.pieces[index].value.toFixed(2),
        height: values.pieces[index].height.toFixed(0),
        width: values.pieces[index].width.toFixed(0),
        length: values.pieces[index].length.toFixed(0),
        amount: "1",
      });
    } else if (option.value === PieceMenuOptions.DELETE) {
      deletePiece(index);
    } else if (option.value === PieceMenuOptions.DUPLICATE) {
      duplicatePiece(index);
    }
  };

  const getAllShipmentItemRates = async () => {
    if (
      values.pieces.length === 0 ||
      !values.consigneeAddress ||
      !values.buSource ||
      !values.buConsignee ||
      baseShipmentItems.length === 0
    ) {
      return;
    }

    let response = await getShipmentRates(
      values.service,
      values.paymentMode,
      values.deliveryType,
      values.buSource.code,
      values.buConsignee.code,
      undefined,
      undefined,
      undefined,
      values.buSource.location,
      values.consigneeAddress,
      baseShipmentItems,
      values.pieces,
      values.pieces.reduce((acc, p) => acc + p.value, 0) > 0,
      new Date().toISOString(),
      applicationID
    );

    if (response.didError || !response.model) {
      return;
    }

    setItems(
      response.model.items
        .filter(
          (item) => item.mandatory || items?.some((s) => s.id === item.id)
        )
        .sort((a, b) => a.order - b.order)
    );
  };

  useEffect(() => {
    if (
      !values.buConsignee?.location.coordinates.lat ||
      !values.buConsignee?.location.coordinates.lng ||
      !values.consigneeAddress?.coordinates.lat ||
      !values.consigneeAddress?.coordinates.lng
    ) {
      setDeliveryDistance(0);
      return;
    }

    const afunction = async () => {
      const distance = await getDeliveryDistance(
        values.buConsignee?.location.coordinates.lat!,
        values.buConsignee?.location.coordinates.lng!,
        values.consigneeAddress?.coordinates.lat!,
        values.consigneeAddress?.coordinates.lng!
      );

      if (!distance.model || distance.didError) return;

      setDeliveryDistance(distance.model.deliveryDistance);
    };

    afunction();
  }, [
    values.buConsignee?.location.coordinates.lat,
    values.buConsignee?.location.coordinates.lng,
    values.consigneeAddress?.coordinates.lat,
    values.consigneeAddress?.coordinates.lng,
  ]);

  useEffect(() => {
    const afunction = async () => {
      const services = await getService(values.paymentMode);

      if (!services.didError && services.model !== null) {
        setService(services.model);
      }
    };

    afunction();
  }, [values.paymentMode]);

  useEffect(() => {
    if (
      !values.totalChargedWeight ||
      !values.buSource?.location?.coordinates.lat ||
      !values.buSource?.location?.coordinates.lng ||
      !values.consigneeAddress?.coordinates.lat ||
      !values.consigneeAddress?.coordinates.lng
    ) {
      return;
    }

    const afunction = async () => {
      const tier = await getTier({
        LatitudeShipperAddress: values.buSource?.location.coordinates.lat,
        LongitudeShipperAddress: values.buSource?.location.coordinates.lng,
        LatitudeConsigneeAddress: values.consigneeAddress?.coordinates.lat,
        LongitudeConsigneeAddress: values.consigneeAddress?.coordinates.lng,
      });
      const response = await getShipmentItems({
        CurrencyID: 2,
        CountryID: 236,
        Weight: values.totalChargedWeight,
        DeclaratedValue: values.declaredValue,
        Distance: deliveryDistance,
        ApplicationID: applicationID,
        ServiceID: values.service,
        PaymentModeID: values.paymentMode,
        DeliveryTypeID: values.deliveryType,
        SRM: !!values.pieces?.reduce((acc, p) => acc + p.value, 0),
        TierID: tier.model?.tierID,
        ShippingMethodID: 10,
        PackageTypeID: 10,
        SalesDate: new Date().toISOString(),
        BUCodeSource: values.buSource?.code,
        BUCodeConsignee: values.buConsignee?.code,
      });

      if (response.didError || !response.model) {
        return;
      }

      // Filter ipostel
      setBaseShipmentItems(
        response.model.filter(
          (item) => !item.name.toLowerCase().includes("ipostel")
        )
      );
    };

    afunction();
  }, [
    applicationID,
    deliveryDistance,
    values.buSource,
    values.buConsignee,
    values.consigneeAddress,
    values.declaredValue,
    values.totalChargedWeight,
    values.service,
    values.paymentMode,
    values.deliveryType,
    values.pieces,
  ]);

  return (
    <div className="flex flex-col gap-6">
      <div className="flex gap-10 justify-between items-center">
        <p className="text-lg text-gray-800 font-semibold">Resumen del envío</p>
      </div>

      <div
        className={classNames(
          "relative flex flex-col border border-gray-300 rounded-lg p-4 pt-3 shadow",
          !values.buSource && "hidden"
        )}
      >
        <p className="text-gray-800 font-semibold mb-2">Tienda de origen:</p>
        <p className="text-sm text-gray-600 font-semibold">
          {values.buSource?.code} - {values.buSource?.location.name}
        </p>
        <p className="text-xs text-gray-600">
          {values.buSource?.location.address}
        </p>

        <button
          type="button"
          className="absolute top-2 right-2 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          onClick={() => setValues({ ...values, buSource: null })}
        >
          <span className="absolute -inset-2.5" />
          <span className="sr-only">Close panel</span>
          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>

      <div
        className={classNames(
          "relative flex flex-col border border-gray-300 rounded-lg p-4 pt-3 shadow",
          (!values.buConsignee || !values.consigneeAddress) && "hidden"
        )}
      >
        <p className="text-gray-800 font-semibold mb-2">
          {values.deliveryType === DeliveryType.AT_OFFICE
            ? "Entrega en tienda:"
            : "Entrega a domicilio:"}
        </p>
        <p
          className={classNames(
            "text-sm text-gray-600 font-semibold",
            values.deliveryType !== DeliveryType.AT_OFFICE && "hidden"
          )}
        >
          {values.buConsignee?.code} - {values.buConsignee?.location.name}
        </p>
        <p
          className={classNames(
            "text-sm text-gray-600 font-semibold",
            values.deliveryType !== DeliveryType.AT_HOME && "hidden"
          )}
        >
          {values.consigneeAddress?.name}
        </p>
        <p className="text-xs text-gray-600">
          {values.consigneeAddress?.address}
        </p>

        <button
          type="button"
          className="absolute top-2 right-2 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          onClick={() =>
            setValues({ ...values, buConsignee: null, consigneeAddress: null })
          }
        >
          <span className="absolute -inset-2.5" />
          <span className="sr-only">Close panel</span>
          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>

      <div className="flex flex-col">
        <p className="text-gray-800 font-semibold mb-2">Piezas:</p>

        {values.pieces.length > 0 && (
          <div className="overflow-x-auto">
            <PieceTable
              editable
              pieces={values.pieces}
              editMode={editMode}
              editIndex={editIndex}
              maxSumWeight={service?.maxWeight}
              onClickPieceMenu={handleMenuClick}
            />

            <div className="flex flex-col w-full items-end text-gray-800 px-4 pb-5 sm:px-6 mt-4">
              <p className="">
                Peso a facturar:{" "}
                <span className="font-semibold">
                  {values.totalChargedWeight?.toFixed(2) ?? "0.00"} Kg
                </span>
              </p>
              <p className="text-xs mt-2">
                Peso balanza:{" "}
                <span className="font-semibold">
                  {values.totalPhysicalWeight?.toFixed(2) ?? "0.00"} Kg
                </span>
              </p>
              <p className="text-xs">
                Peso volumétrico:{" "}
                <span className="font-semibold">
                  {values.totalDimensionalWeight?.toFixed(2) ?? "0.00"}{" "}
                  Kg
                </span>
              </p>
            </div>
          </div>
        )}

        {values.pieces.length === 0 && (
          <p className="text-gray-600 text-sm">No hay piezas agregadas</p>
        )}
      </div>

      <hr className="border-gray-300 mt-2" />

      <div className="flex flex-col">
        <div className="flex items-center gap-10 justify-center md:justify-between">
          <p className="text-gray-800 font-semibold mb-2 hidden md:block">
            Servicios:
          </p>

          <PrimaryButton
            className="w-48 md:w-32"
            onClick={getAllShipmentItemRates}
            disabled={!isQuotable}
          >
            Cotizar
          </PrimaryButton>
        </div>

        <div className="overflow-x-auto">
          <ShipmentItemTable
            showTaxes
            items={items}
            distance={deliveryDistance}
            declaredValue={values.declaredValue}
          />
          {items.length > 0 && (
            <div className="flex justify-end mt-2">
              <p className="text-right text-xs text-gray-600 text-balance">
                Los montos en bolívares se calculan a la tasa de cambio del
                Banco Central de Venezuela (BCV).
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PriceCalculatorResume;
