import React, { FC, useEffect, useMemo, useState } from "react";
import {
  BusinessUnitInterface,
  DeliveryType,
  LocationInterface,
  PaymentMode,
  PieceInterface,
  ShipmentService,
} from "../../interfaces";
import { BusinessUnitSearch } from "../../components/LocationSearch";
import LocationSelect from "./LocationSearch";
import {
  FormSimpleRadioGroup,
  FormTextInput,
} from "../../components/FormFields";
import { paymentModeFormat } from "../../utils";
import PieceForm from "../../components/Shipment/PieceForm";
import { LinkText, PrimaryButton } from "../../components/Buttons";
import { handleNumberChange, useVerifyDeclaredValue } from "../../utils/forms";
import {
  getAppLockedServices,
  getLockBUServices,
  getServicesFromDeliveryType,
  getServicesFromPaymentMode,
  getWeights,
} from "../../services";
import { ServiceDTO } from "../../interfaces/Dtos/ServiceDTO";
import { useAppSelector } from "../../store/hooks";

const PAYMENT_MODE_OPTIONS = [
  {
    name: paymentModeFormat(PaymentMode.CONTADO),
    value: PaymentMode.CONTADO,
  },
  {
    name: paymentModeFormat(PaymentMode.COD),
    value: PaymentMode.COD,
  },
];

export const PIECES_INITIAL_VALUES = {
  category: "",
  weight: "",
  value: "",
  height: "",
  width: "",
  length: "",
  amount: "1",
};

export interface PriceCalculatorFormValues {
  buSource: BusinessUnitInterface | null;
  deliveryType: DeliveryType;
  buConsignee: BusinessUnitInterface | null;
  consigneeAddress: LocationInterface | null;
  paymentMode: PaymentMode;
  service: ShipmentService;
  pieces: PieceInterface[];
  declaredValue: number;
  totalChargedWeight: number;
  totalPhysicalWeight: number;
  totalDimensionalWeight: number;
}

interface PriceCalculatorFormErrors {
  declaredValue: string;
}

interface PriceCalculatorFormProps {
  values: PriceCalculatorFormValues;
  editMode: boolean;
  editIndex: number;
  initialValues: typeof PIECES_INITIAL_VALUES;
  clearItems: () => void;
  setValues: (values: PriceCalculatorFormValues) => void;
  setEditMode: (mode: boolean) => void;
  setInitialValues: (values: typeof PIECES_INITIAL_VALUES) => void;
}

const PriceCalculatorForm: FC<PriceCalculatorFormProps> = ({
  values,
  editMode,
  editIndex,
  initialValues,
  clearItems,
  setValues,
  setEditMode,
  setInitialValues,
}) => {
  const applicationID = useAppSelector(
    (state) => state.inmutable.appData.applicationID
  );

  const [errors, setErrors] = useState<PriceCalculatorFormErrors>({
    declaredValue: "",
  });
  const [declaredValue, setDeclaredValue] = useState("");
  const verifyDeclaredValue = useVerifyDeclaredValue(values.totalChargedWeight);
  const [paymentModeServices, setPaymentModeServices] = useState<ServiceDTO[]>(
    []
  );
  const [deliveryTypeServices, setDeliveryTypeServices] = useState<
    ServiceDTO[]
  >([]);
  const [lockedBUServices, setLockedBUServices] = useState<ServiceDTO[]>([]);
  const [allowedServices, setAllowedServices] = useState<ServiceDTO[]>([]);
  const [appBlockedServices, setAppBlockedServices] = useState<ServiceDTO[]>(
    []
  );

  const availableServices = useMemo(() => {
    return allowedServices.map((service) => {
      return {
        name: service.serviceShortName,
        value: service.serviceID,
      };
    });
  }, [allowedServices]);

  const handlePieceChange = (piece: PieceInterface, amount: number) => {
    const pieces = [...values.pieces];

    if (editMode) {
      pieces[editIndex] = piece;
      setEditMode(false);
    } else {
      for (let i = 0; i < amount; i++) {
        pieces.push(piece);
      }
    }

    const newPieces = pieces.map((piece) => ({
      ...piece,
      value: values.declaredValue / pieces.length,
    }));
    setValues({ ...values, pieces: newPieces });
    setInitialValues(PIECES_INITIAL_VALUES);
  };

  const handleDeclaredValueChange = () => {
    const value = parseFloat(declaredValue);

    const { didError, error } = verifyDeclaredValue(
      declaredValue,
      values.service,
      values.paymentMode
    );
    if (didError) {
      setErrors({ ...errors, declaredValue: error });
      return;
    }

    const pieces = values.pieces.map((piece) => ({
      ...piece,
      value: value / values.pieces.length,
    }));
    setValues({ ...values, declaredValue: value, pieces });
  };

  useEffect(() => {
    if (values.pieces.length === 0) {
      setValues({
        ...values,
        totalChargedWeight: 0,
        totalPhysicalWeight: 0,
        totalDimensionalWeight: 0,
      });
      return;
    }

    const afunction = async () => {
      const response = await getWeights(236, 10, values.pieces);

      if (response.didError || !response.model) return;

      setValues({
        ...values,
        totalChargedWeight: response.model.chargedWeight,
        totalPhysicalWeight: response.model.physicalWeight,
        totalDimensionalWeight: response.model.dimensionalWeight,
      });
    };

    afunction();
  }, [values.pieces]);

  useEffect(() => {
    const paymentMode = values.paymentMode;
    if (paymentMode === undefined) {
      setPaymentModeServices([]);
      return;
    }

    const getPaymentModeServices = async () => {
      const services = await getServicesFromPaymentMode(paymentMode);

      if (!services.didError && services.model !== null) {
        setPaymentModeServices(services.model);
      }
    };

    getPaymentModeServices();
  }, [values.paymentMode]);

  useEffect(() => {
    const deliveryType = values.deliveryType;
    if (deliveryType === undefined) {
      setDeliveryTypeServices([]);
      return;
    }

    const getDeliveryTypeServices = async () => {
      const services = await getServicesFromDeliveryType(deliveryType);

      if (!services.didError && services.model !== null) {
        setDeliveryTypeServices(services.model);
      }
    };

    getDeliveryTypeServices();
  }, [values.deliveryType]);

  useEffect(() => {
    const buSource = values.buSource?.code;
    if (buSource === undefined) {
      setLockedBUServices([]);
      return;
    }

    const getLockedServices = async () => {
      const services = await getLockBUServices(buSource);

      if (!services.didError && services.model !== null) {
        setLockedBUServices(services.model);
      }
    };

    getLockedServices();
  }, [values.buSource?.code]);

  useEffect(() => {
    if (applicationID === undefined) {
      setAppBlockedServices([]);
      return;
    }

    const getLockedServices = async () => {
      const services = await getAppLockedServices(applicationID);

      if (!services.didError && services.model !== null) {
        setAppBlockedServices(services.model);
      }
    };

    getLockedServices();
  }, [applicationID]);

  useEffect(() => {
    const allowedServices = paymentModeServices.filter(
      (service) =>
        deliveryTypeServices.some((s) => s.serviceID === service.serviceID) &&
        (service.receptionInBU ||
          values.deliveryType !== DeliveryType.AT_OFFICE) &&
        !lockedBUServices.some((s) => s.serviceID === service.serviceID) &&
        !appBlockedServices.some((s) => s.serviceID === service.serviceID)
    );
    setAllowedServices(allowedServices);

    if (allowedServices.length === 1) {
      setValues({ ...values, service: allowedServices[0].serviceID });
    }
  }, [
    paymentModeServices,
    deliveryTypeServices,
    lockedBUServices,
    appBlockedServices,
    values.deliveryType,
  ]);

  return (
    <div className="flex flex-col gap-6">
      <div className="flex w-full justify-end -mb-4">
        <LinkText
          text="Limpiar formulario"
          onClick={() => {
            clearItems();
            setDeclaredValue("");
            setValues({
              buSource: null,
              deliveryType: DeliveryType.AT_OFFICE,
              buConsignee: null,
              consigneeAddress: null,
              paymentMode: PaymentMode.CONTADO,
              service: ShipmentService.STANDARD,
              pieces: [],
              declaredValue: 0,
              totalChargedWeight: 0,
              totalPhysicalWeight: 0,
              totalDimensionalWeight: 0,
            });
          }}
        />
      </div>

      <div className="flex flex-col gap-2">
        <p className="text-gray-800 font-semibold">Elegir tienda de origen</p>
        <BusinessUnitSearch
          selected={values.buSource ?? undefined}
          buSource={values.buSource ?? undefined}
          setSelectedLocation={(buSource) => setValues({ ...values, buSource })}
        />
      </div>

      <hr className="border-gray-300 mt-2" />

      <div className="flex flex-col gap-2">
        <p className="text-gray-800 font-semibold">Elegir destino</p>
        <LocationSelect
          buSource={values.buSource ?? undefined}
          deliveryType={values.deliveryType}
          selectedBusinessUnit={values.buConsignee ?? undefined}
          selectedLocation={values.consigneeAddress ?? undefined}
          setLocationType={(deliveryType) =>
            setValues({
              ...values,
              deliveryType,
              consigneeAddress: null,
              buConsignee: null,
            })
          }
          setLocation={(location, buConsignee) =>
            setValues({
              ...values,
              consigneeAddress: location ?? null,
              buConsignee: buConsignee ?? null,
            })
          }
        />
      </div>

      <hr className="border-gray-300 mt-2" />

      <div className="flex flex-col gap-2">
        <p className="text-gray-800 font-semibold">Modalidad de pago</p>
        <FormSimpleRadioGroup
          horizontal
          name="paymentMode"
          selected={values.paymentMode}
          options={PAYMENT_MODE_OPTIONS}
          onSelectOption={(paymentMode) =>
            setValues({ ...values, paymentMode })
          }
        />
      </div>

      <hr className="border-gray-300 mt-2" />

      <div className="flex flex-col gap-2">
        <p className="text-gray-800 font-semibold">Servicio</p>
        <FormSimpleRadioGroup
          horizontal
          name="service"
          selected={values.service}
          options={availableServices}
          onSelectOption={(service) =>
            setValues({ ...values, service, pieces: [] })
          }
        />
      </div>

      <hr className="border-gray-300 mt-2" />

      <div className="flex flex-col gap-2">
        <p className="text-gray-800 font-semibold">Añadir piezas</p>
        <PieceForm
          editMode={editMode}
          type={values.service}
          initialValues={initialValues}
          pieces={values.pieces}
          onSubmit={handlePieceChange}
          setEditMode={setEditMode}
        />
      </div>

      <hr className="border-gray-300 mt-2" />

      <div className="flex flex-col gap-2 w-full">
        <p className="text-gray-800 font-semibold">
          Valor declarado en dólares
        </p>

        <div className="flex gap-4 w-full">
          <div className="flex-1">
            <FormTextInput
              value={declaredValue}
              error={errors.declaredValue}
              onChange={(event) => {
                if (handleNumberChange(event)) {
                  const { didError, error } = verifyDeclaredValue(
                    event.target.value,
                    values.service,
                    values.paymentMode
                  );
                  if (didError) {
                    setErrors({ ...errors, declaredValue: error });
                  } else {
                    setErrors({ ...errors, declaredValue: "" });
                  }
                  setDeclaredValue(event.target.value);
                }
              }}
              onClick={handleDeclaredValueChange}
              name="declaredValue"
            />
          </div>

          <div>
            <PrimaryButton
              className="w-24 md:w-32"
              disabled={parseFloat(declaredValue) === values.declaredValue}
              onClick={handleDeclaredValueChange}
            >
              Aplicar
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceCalculatorForm;
